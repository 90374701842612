import React from "react"
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import styled from "styled-components"
import breakpoints from "../../styles/breakpoints"

const HighlightsGrid = styled.div`
  display: grid;
  gap: 1.25em;
  padding: 0 1.25em;
  margin: 0 auto;

  @media (min-width: ${breakpoints.md}) {
    grid-template-columns: repeat(2, 1fr);
  }
`

const HighlightCard = styled.div`
  position: relative;
`

const HighlightLink = styled(Link)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

const HighlightsBlock = ({ highlights }) => {
  if (!highlights) return null
  return (
    <HighlightsGrid>
      {highlights.map((highlight, index) => {
        return (
          <HighlightCard key={index}>
            <GatsbyImage
              image={highlight.thumbnail.gatsbyImageData}
              alt={highlight.work.title}
            />
            <HighlightLink to={`/${highlight.work.slug}`} />
          </HighlightCard>
        )
      })}
    </HighlightsGrid>
  )
}

export default HighlightsBlock
