import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/Layout/Layout"
import Seo from "../components/Core/Seo"
import { GatsbyImage } from "gatsby-plugin-image"
import styled from "styled-components"
import breakpoints from "../styles/breakpoints"
import variables from "../styles/variables"
import HighlightsBlock from "../components/Page/HighlightsBlock"
import TitleBlock from "../components/Page/TitleBlock"

const PageContents = styled.div`
  display: grid;
  gap: 2em;

  @media (min-width: ${breakpoints.md}) {
    gap: 4em;
  }
`

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;

  @media (min-width: ${breakpoints.md}) {
    padding-left: 5em;
    padding-right: 5em;
  }
`

const WorkCard = styled.div`
  padding-right: 1.25em;
  padding-left: 1.25em;
  width: 100%;

  @media (min-width: ${breakpoints.md}) {
    width: 50%;
  }

  @media (min-width: ${breakpoints.xl}) {
    width: calc(100% / 3);
  }
`

const WorkCardImageLink = styled(Link)`
  display: block;
`

const WorkCardLink = styled(Link)`
  :hover {
    text-decoration: underline;
    text-underline-position: under;
  }

  font-size: 14.4px;
  display: block;
  padding-top: 12px;
  padding-bottom: 2.5em;
`

const OtherWorkCardContainer = styled.div`
  padding: 1em;
  margin-left: 1.25em;
  margin-right: 1.25em;
  margin-bottom: calc(1em + 60px);
  width: calc(100% - 2.5em);
  height: calc(((100vw - 2.5em) * 0.75) - 4px);
  border: 2px solid ${variables.colorBlackFG};

  @media (min-width: ${breakpoints.md}) {
    margin-left: 2.25em;
    margin-right: 2.25em;
    width: calc(50% - 4.5em);
    height: calc(((50vw - 4.5em) * 0.75) - 4px);
  }

  @media (min-width: ${breakpoints.xl}) {
    width: calc((100% / 3) - 4.5em);
    height: calc((((100vw / 3) - 4.5em) * 0.75) - 4px);
  }

  @media (min-width: ${breakpoints.xxl}) {
    width: calc(25% - 4.5em);
    height: calc(((25vw - 4.5em) * 0.75) - 4px);
  }

  @media (min-width: ${breakpoints.xxxl}) {
    width: calc(20% - 4.5em);
    height: calc(((20vw - 4.5em) * 0.75) - 4px);
  }
`

const Dash = styled.div`
  margin: 0.8em auto;
  border: none;
  height: 0.14em;
  background-color: black;
  width: 1em;
`

const StyledLink = styled(Link)`
  text-underline-position: under;

  text-decoration: ${(props) => (props.underline ? "underline" : "none")};

  :hover {
    text-decoration: underline;
  }

  text-align: center;
  display: block;
  width: 100%;
`
const PageTemplate = ({ location, data }) => {
  const { page } = data

  const { slug, description, work, highlights, titleBlock, metaTitle } = page

  const isHomepage = slug === "home"

  const socialSharingImage = work[0]?.thumbnail?.url || null

  const path = location.pathname

  return (
    <Layout>
      <Seo
        title={metaTitle}
        fullTitle={isHomepage}
        description={description}
        image={socialSharingImage}
        path={path}
      />
      <PageContents>
        <HighlightsBlock highlights={highlights} />
        <TitleBlock titles={titleBlock} />
        <Container>
          {work &&
            work.map(({ work, thumbnail }, index) => {
              if (!work) return null
              return (
                <WorkCard key={index}>
                  <WorkCardImageLink to={`/${work.slug}`}>
                    <GatsbyImage
                      alt={work.title}
                      image={thumbnail.gatsbyImageData}
                    />
                  </WorkCardImageLink>
                  <WorkCardLink to={`/${work.slug}`} homepage={isHomepage}>
                    {!isHomepage && work.title}
                  </WorkCardLink>
                </WorkCard>
              )
            })}
          {/*{otherWork &&*/}
          {/*<OtherWorkCardContainer>*/}
          {/*  <h3>*/}
          {/*    <StyledLink to="/commissions/all">*/}
          {/*      Other*/}
          {/*    </StyledLink>*/}
          {/*    <StyledLink to="/commissions/all" underline={true}>*/}
          {/*      Commissioned Projects:*/}
          {/*    </StyledLink>*/}
          {/*  </h3>*/}
          {/*  <Dash></Dash>*/}
          {/*  {*/}
          {/*    otherWork.map(({ title, slug }, index) => {*/}
          {/*      return (*/}
          {/*        <StyledLink to={`/${slug}`} key={index}>*/}
          {/*          {title}*/}
          {/*        </StyledLink>*/}
          {/*      )*/}
          {/*    })*/}
          {/*  }*/}
          {/*</OtherWorkCardContainer>}*/}
        </Container>
      </PageContents>
    </Layout>
  )
}

export default PageTemplate

export const pageTemplateQuery = graphql`
  query($slug: String!) {
    page: contentfulPage(slug: { eq: $slug }) {
      slug
      title
      metaTitle
      description
      highlights {
        id
        thumbnail {
          id
          gatsbyImageData(width: 1600, aspectRatio: 1.333)
          url
        }
        work {
          title
          slug
        }
      }
      titleBlock {
        desktopTitle
        mobileTitle
        description {
          raw
        }
      }
      work {
        id
        thumbnail {
          id
          gatsbyImageData(width: 800, aspectRatio: 1.333)
          url
        }
        work {
          id
          title
          slug
        }
      }
      # otherWork {
      #   title
      #   slug
      # }
    }
  }
`
