import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import breakpoints from "../../styles/breakpoints"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { INLINES } from "@contentful/rich-text-types"
import { useLocation } from "@reach/router"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;

  max-width: 300px;
  margin: auto;

  @media (min-width: ${breakpoints.md}) {
    max-width: 400px;
  }
`

const Title = styled.h1`
  text-align: center;
  font-size: 20px;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: 8px;
  
  @media (min-width: ${breakpoints.md}) {
    margin-bottom: 20px;
    letter-spacing: 2px;
    font-size: 30px;
  }

  @media (min-width: ${breakpoints.lg}) {
    font-size: 32px;
  }
`

const MobileTitle = styled(Title)`
  display: block;
  @media (min-width: ${breakpoints.md}) {
    display: none;
  }
`
const DesktopTitle = styled(Title)`
  display: none;
  @media (min-width: ${breakpoints.md}) {
    display: block;
  }
`

const Description = styled.div`
  font-size: 12px;
  line-height: 1.5;
  height: min-content;

  a {
    color: inherit;
    text-decoration: underline;
    :hover {
      text-decoration: none;
    }
  }

  i {
    font-style: italic;
  }
  @media (min-width: ${breakpoints.md}) {
    font-size: 14px;
  }
  @media (min-width: ${breakpoints.lg}) {
    font-size: 16px;
  }
`

const TitleBlock = ({ titles }) => {
  const location = useLocation()
  if (!titles) return null

  const { desktopTitle, mobileTitle, description } = titles

  const desktop = desktopTitle.join(" + ")
  const mobile = mobileTitle?.join(" + ") || desktop

  const options = {
    renderNode: {
      [INLINES.HYPERLINK]: ({ data }, children) => {
        const isInternalLink =
          data.uri.includes(location.origin) || data.uri.startsWith("/")

        if (isInternalLink) {
          return <Link to={data.uri}>{children}</Link>
        }

        return (
          <a href={data.uri} target="_blank" rel="noopener noreferrer">
            {children}
          </a>
        )
      },
    },
  }
  return (
    <Container>
      <MobileTitle dangerouslySetInnerHTML={{ __html: mobile }} />
      <DesktopTitle dangerouslySetInnerHTML={{ __html: desktop }} />
      <Description>{renderRichText(description, options)}</Description>
    </Container>
  )
}

export default TitleBlock
